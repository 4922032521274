import { styled } from '@mui/system'
import Typography from '../../atoms/typography/typography'

const shouldForwardProp = (prop) => !['addMargin'].includes(prop)

const TriggerWrapper = styled('div', {
	shouldForwardProp,
})`
	display: flex;
	align-items: center;
	cursor: pointer;
`

const CountryCode = styled(Typography, { shouldForwardProp })`
	margin-left: 0;
	margin-right: 4px;
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	line-height: 1;
`

const Separator = styled(Typography)`
	margin: 0 17px;
	color: ${({ theme }) => theme.palette.grey.light};
`

const CurrencySymbol = styled(Typography)`
	margin-right: 7px;
`

const Language = styled(Typography, { shouldForwardProp })`
	margin-right: ${({ addMargin }) => (addMargin ? '17px' : 0)};
`

export { CountryCode, CurrencySymbol, Language, Separator, TriggerWrapper }
