import { styled } from '@mui/system'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Link from '../../molecules/link'
import Accordion from '../../molecules/accordion'
import Typography from '../../atoms/typography'

const Wrapper = styled('div')`
	flex-grow: 1;
	order: 2;
	border: none;
	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		order: 1;
	}
	& .MuiPaper-root {
		background: none;
		border: none;
		${({ theme }) => theme.breakpoints.up('md')} {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			border: none;
		}
		& .MuiAccordionSummary-root {
			min-height: auto;
			padding: 20px 0;
			${({ theme }) => theme.breakpoints.up('md')} {
				padding: 0;
				margin-bottom: 24px;
			}
		}
		& .MuiAccordionSummary-expandIconWrapper {
			color: ${({ theme }) => theme.palette.primary.main};
			.MuiSvgIcon-root {
				font-size: ${({ theme }) => theme.typography.pxToRem(24)};
			}
			${({ theme }) => theme.breakpoints.up('md')} {
				display: none;
			}
		}
		& .MuiAccordionDetails-root {
			padding: 0 20px 30px;
			${({ theme }) => theme.breakpoints.up('md')} {
				padding: 0;
			}
		}
	}
`

const ListStyled = styled(List)`
	display: flex;
	flex-direction: column;
`

const ListItemStyled = styled(ListItem)`
	flex-direction: column;
	align-items: flex-start;
`

const NavLink = styled(Link)`
	color: ${({ theme }) => theme.palette.primary.main};
	line-height: 40px;
	font-weight: 400;
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	> div {
		display: flex;
		align-items: center;
		.MuiSvgIcon-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(16)};
			margin-right: 12px;
		}
	}
`

const AccordionStyled = styled(Accordion)`
	${({ theme }) => theme.breakpoints.up('md')} {
		& .MuiCollapse-root {
			transition-duration: 0ms !important;
		}
		& .MuiAccordionSummary-content {
			cursor: text;
		}
	}
`

const TypographyStyled = styled(Typography)`
	color: ${({ theme }) => theme.palette.white.main};
	display: flex;
	flex-grow: 1;
	padding: 22px;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0;
	}
`

export { Wrapper, ListStyled, ListItemStyled, NavLink, AccordionStyled, TypographyStyled }
