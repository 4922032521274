import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'

const MiddleBottomWrapper = styled('div')`
	flex-wrap: wrap;
	padding: 0;
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	border-top: 0;
	padding: 0;
	& .BHFooterSocialLinks {
		width: 100%;
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: ${({ theme }) => theme.spacing(5, 0)};
		border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
		margin-bottom: ${({ theme }) => theme.spacing(2)};
		${({ theme }) => theme.breakpoints.up('md')} {
			display: none;
			width: auto;
			justify-content: flex-end;
			padding: 0;
			border-bottom: 0;
			margin-bottom: 0;
		}
		& .BHSocialWrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			column-gap: 25px;
			max-width: 240px;
			margin: auto;
			overflow: hidden;
			div {
				display: flex;
				align-items: center;
				gap: ${({ theme }) => theme.spacing(1.2)};
			}
			& a {
				margin: 0;
				padding: 0;
				color: ${({ theme }) => theme.palette.primary.main};
			}

			${({ theme }) => theme.breakpoints.up('md')} {
				width: unset;
				max-width: unset;
				margin: 0;
			}
		}
	}
	& .footer-storelocator-link {
		display: none;
		width: 50%;
		justify-content: center;
		padding: 30px 0;
		border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		border-radius: 0;
		&:hover,
		&:active {
			border-bottom: 1px solid ${({ theme }) => theme.palette.footer.border};
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			width: auto;
			border-bottom: none;
			padding: 0;
			&:hover,
			&:active {
				border-bottom: none;
			}
		}
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	&.change-country-language-trigger-footer {
		text-transform: uppercase;
		flex-grow: 1;
		justify-content: center;
		border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};
		border-right: 0;
		padding: ${({ theme }) => theme.spacing(5)};
		${({ theme }) => theme.breakpoints.up('md')} {
			flex-grow: 0;
			width: auto;
			border: unset;
			margin-top: 0px;
		}
	}
	&.change-country-language-trigger-footer-inside {
		padding: ${({ theme }) => theme.spacing(5, 0, 4)};
		color: ${({ theme }) => theme.palette.primary.main};
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper }
