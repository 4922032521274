/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '@bluheadless/ui/src/atoms/icon/icon'

const WorldIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20.9 15.4v-.1c.4-1 .6-2.1.6-3.3 0-1.1-.2-2.2-.6-3.3v-.1c-1.3-3.3-4.3-5.7-7.9-6.1h-2c-3.6.4-6.6 2.8-7.9 6.1v.1c-.4 1-.6 2.1-.6 3.3s.2 2.2.6 3.3v.1c1.3 3.3 4.3 5.7 8 6.1h1.8c3.7-.4 6.7-2.8 8-6.1zM3.4 12c0-.9.2-1.9.4-2.7h4.3c-.1.8-.2 1.7-.2 2.7s.1 1.9.2 2.7H3.9c-.3-.8-.5-1.8-.5-2.7zm5.4 0c0-1 .1-1.9.2-2.7h5.9c.1.8.2 1.7.2 2.7 0 1-.1 1.9-.2 2.7H9.1c-.2-.8-.3-1.7-.3-2.7zm7-2.7h4.3c.3.9.4 1.8.4 2.7 0 .9-.2 1.9-.4 2.7h-4.3c.1-.8.2-1.7.2-2.7s0-1.9-.2-2.7zm4-.9h-4.1c-.5-2.2-1.3-3.8-1.8-4.8 2.6.6 4.8 2.4 5.9 4.8zm-7.1-5c.3.6 1.4 2.4 2 5H9.3c.6-2.6 1.7-4.4 2-5h1.4zm-2.5.2c-.6.9-1.4 2.6-1.9 4.8H4.2c1.1-2.4 3.3-4.2 6-4.8zm-6 12h4.1c.5 2.2 1.3 3.8 1.8 4.8-2.6-.6-4.8-2.4-5.9-4.8zm7.1 5c-.3-.6-1.4-2.4-2-5h5.5c-.6 2.6-1.7 4.4-2.1 4.9H12c-.2.1-.4.1-.7.1zm2.5-.2c.5-.9 1.4-2.6 1.8-4.8h4.1c-1 2.4-3.2 4.2-5.9 4.8z" />
	</svg>
))
WorldIconSvg.displayName = 'WorldIconSvg'

const WorldIcon = forwardRef((props, ref) => <Icon component={WorldIconSvg} ref={ref} {...props} />)
WorldIcon.displayName = 'WorldIcon'

WorldIcon.defaultProps = {
	...Icon.defaultProps,
}
WorldIcon.propTypes = {
	...Icon.propTypes,
}

export default WorldIcon
export { WorldIconSvg }
